import { Heading } from '@lmig/lmds-react';
import React, { Component, Fragment } from 'react';


export class AppDisabled extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            messages: []
        }

        this.props = props;
    }

    render() {
        return (
            <Fragment>
                <div className='sectionContainer'>
                    <div className='sectionContent' onSubmit={() => null}>  
                        <div className='row'>
                            <div className='col-sm-7'>
                                <Heading id='splashHeading' type='h5'><br/>Tuition insurance is currently not available.</Heading>
                                <Heading id='splashHeading' type='h5'>Please check back with us here. If you have any questions, contact us at <a href="mailto:TuitionInsurance@LibertyMutual.com">TuitionInsurance@LibertyMutual.com</a>.</Heading>
                            </div>
                            <div className='col-sm-5 mx-auto my-auto d-none d-sm-block'>
                                <img className='img-fluid mt-4' alt='Notebook and pencil holder' src={require('../style/img/Education2.png')} />
                            </div>
                        </div>
                    </div>                    
                </div>
            </Fragment>
        );
    }
}