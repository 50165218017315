import { SETBREADCRUMB } from './actions/breadcrumb'

function breadcrumbReducer(state = 0, action) {
    switch(action.type) {
        case SETBREADCRUMB:
            return action.crumbId
        default: 
            return state; 
    }
}

export default breadcrumbReducer;