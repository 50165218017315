import "core-js";
import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { EnvironmentService } from './services/EnvironmentService';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import * as constants from './utils/Constants';
import { Content, IconAlert } from '@lmig/lmds-react';
import Header from './components/TuitionHeader';
import Footer from './components/TuitionFooter';
import './style/scss/collegeinfo.scss';

//Redux
import { connect } from 'react-redux';
import AppDisabledContainer from "./forms/containers/AppDisabledContainer";

class App extends Component {

    constructor() {
        super();

        this.environmentService = new EnvironmentService();
        this.eSalesEnsightenPost = this.eSalesEnsightenPost.bind(this);

        this.state = {
            'rateQuoteIndicator': true,
            'isTestEnvironment': false
        };
    }

    componentWillMount() {
        ReactPixel.init(constants.facebookPixelId);
        ReactPixel.pageView();
        ReactGA.initialize(constants.googleAnalyticsId);
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.enableLoadingOverlay();
        this.environmentService.getEnvironment().then(response => {
            if (response.environment === 'test' || response.environment === 'perf') {
                this.setState({
                    isTestEnvironment: true
                });
            }
        });
    }

    enableLoadingOverlay() {
        this.setState({
            enableLoadingOverlay: true
        });
    }

    disableLoadingOverlay() {
        this.setState({
            enableLoadingOverlay: false
        });
    }

    render() {
        return (
            <Fragment>
                <div id='main' className="container">
                    <div className="lm-app">
                        <Header />
                        <Content>
                            {this.state.isTestEnvironment &&
                                <div className="sectionContainer">
                                    <div className="sectionContent">
                                        <div className="card alertCard">
                                            <div className="alert-message state-error state-no-margin">
                                                <div className="alert-icon">
                                                    <IconAlert className="alert-icon-color" title="IconAlert" sizing='auto'/>
                                                </div>
                                                <div className="alert-content">
                                                    <span className="highlight">This is a test site and cannot be used to purchase tuition insurance.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <Breadcrumb /> */}
                            <div className='main'>
                                <Router>
                                    <Switch>
                                        <Route exact path='/'
                                            component={
                                                () => <AppDisabledContainer
                                                        {...this.props}
                                                        eSalesEnsightenPost={this.eSalesEnsightenPost} 
                                                    />
                                            }
                                        />
                                    </Switch>
                                </Router>
                            </div>
                        </Content>
                        <Footer />
                    </div>
                </div>
            </Fragment>
        );
    }

    eSalesEnsightenPost(formName) {
        //Note: Ensighten won't load digitalData locally. To ensure it doesn't break the eSales developers, all localhost URL's are blocked
        //      from pulling the deployments down. However, your machine address can be added as an exception to the non-prod environment. 

        /* eslint-disable */
        if(window.digitalData && window.digitalData.eventListeners && window.digitalData.eventListeners.post) {            
            let stateCode = this.state.collegeDetails? this.state.collegeDetails.collegeState : '';
            window.digitalData.eventListeners.post({
                tileCode: `ti_${formName}`, //'ti' prefix is for 'tuition insurance'
                stateCode: stateCode
            })  
        }
        /* eslint-enable */
    }
}


const mapStateToProps = state => {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(App));
