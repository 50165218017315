module.exports = {

    // Commercial States
    commercialStates: ['RI'],

    // Billing Constants
    requestSourceCode: 'TUI',
    productTypeCode: 'TUIONLNE',
    getKeyRequestTypeCode: 'GKEY',
    getKeyChannelTypeCode: 'ONLINE',
    getKeyRoute: '/billing/chase/key',
    savePaymentRoute: '/billing/chase/account',
    makePaymentRoute: '/billing/chase/payment',
    saveAccountRequestTypeCode: 'SRA',
    finUserId: 'USCMTuition',
    saveAccountChannelUserType: 'eSales',
    finAccountOwnershipType: 'Personal',
    makePaymentRequestTypeCode: 'SP',
    makePaymentNotificationMode: 'N',
    paymentScheduleInd: 'A',

    // Publishing constants
    apiKeyNonProd: '9E7ZgKZwuu6rHARtHY3bijJhrcS5Re2X',
    apiKeyProd: 'APsRQpXmilAcTGppQtCiVVJL0z4jwrv0',
    publishingId: 'Tuition',

    // Support Email addresses
    productionSupportEmail: 'TuitionInsurance@LibertyMutual.com',
    nonProductionSupportEmail: 'TuitionMonitoring@LibertyMutual.com',
    noReplyEmail: 'DO_NOT_REPLY@tuition.libertymutual.com',

    // Application Error Messages
    genericPaymentsErrorMessage: 'Sorry we are unable to take payments at this time. Please try again later or contact us.',
    declinedPaymentErrorMessage: 'Sorry - your card payment was rejected or declined. Please try again.',
    studentInfoAndCheckboxErrorMessage: 'Please ensure all required fields are complete, confirm all provided information is correct, and agree to the Terms and Conditions.',

    // FaceBook Pixel constants
    facebookPixelId: "1441372226170327",

    // Google Analytics
    googleAnalyticsId: "UA-110206909-1"
};