import React, { Component, Fragment } from 'react';
import LoadingOverlay from '../../components/LoadingOverlay';
import { AppDisabled } from '../AppDisabled';

export default class AppDisabledContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            enableLoadingOverlay: false,
        }

        this.props = props;
        this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);
        this.disableLoadingOverlay = this.disableLoadingOverlay.bind(this);
        this.resetStudyAbroad = false;
    }

    componentDidMount() {
        this.enableLoadingOverlay();
        window.scrollTo(0, 0);

        document.title = 'Liberty Mutual - Tuition Insurance';
        this.props.eSalesEnsightenPost('tuitionDisabled');
        this.disableLoadingOverlay();
    }
    
    enableLoadingOverlay() {
        this.setState({
            enableLoadingOverlay: true
        });
    }

    disableLoadingOverlay() {
        this.setState({
            enableLoadingOverlay: false
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.enableLoadingOverlay ?
                    <div className="container">
                        <div className='overlay'>
                            <LoadingOverlay />
                        </div>
                    </div>
                    :
                    <AppDisabled />
                }
            </Fragment>
        );
    }
}