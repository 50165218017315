import React, { Component } from 'react';
import { Header, GridRow, GridCol, LogoLibertyMutualHoriz, Link } from '@lmig/lmds-react';
import { IconHelp } from '@lmig/lmds-react/icons';

//Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class TuitionHeader extends Component {
    render() {
        return (
            <Header>
                <GridRow vAlign="middle" justify="between">
                    <GridCol base="flex-auto">
                        <LogoLibertyMutualHoriz />
                    </GridCol>
                    <GridCol base="flex-none">
                        <Link onBackground="yellow" variant="standalone" caretPosition="none"
                            href="https://www.libertymutual.com/tuition-insurance/tuition-insurance-faqs" target="_blank" rel="noreferrer noopener">                            
                            Need help?
                            <IconHelp style={{ marginLeft: '0.5rem' }} />
                        </Link>
                    </GridCol>
                </GridRow>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return { ...state };
}

const mapDispatchToProps = dispatch => {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TuitionHeader));
