
export class EnvironmentService {
    getEnvironment() {
        var response;
        if (window.location.href.includes("test")) {
            response = "test"
        }
        else if (window.location.href.includes("perf")) {
            response = "perf"
        }
        else if (window.location.href.includes("dev")) {
            response = "dev"
        }
        else if (window.location.href.includes("local")) {
            response = "local"
        }
        else {
            response = "prod"
        }
        return Promise.resolve(response)
    }

    isSplashPageDisplayed() {
        return Promise.resolve("true")
    }
}
