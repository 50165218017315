/**
 * action types
 */
export const SETBREADCRUMB = 'SETBREADCRUMB'

/*
 * action creators
 */
export function setBreadCrumb(id) {
    return { type: SETBREADCRUMB, step: -1, crumbId: id }
}
