import React, { Component } from 'react';
import { Footer, Disclaimer } from '@lmig/lmds-react';

class TuitionFooter extends Component {

    render() {
        const year = new Date().getFullYear();
        const verizon = require('./../style/img/verizon.png');
        const bbb = require('./../style/img/bbbv2.png');
        const verisign = require('./../style/img/verisignv2.png');

        return (
            <Footer>
                <div ref="footer">
                    <div className="footer-nav2" role="navigation">
                        <ul className="list-group legal col-xl-12 col-lg-12 col-md-12 col-sm-12 col-6">
                            <li>
                                <a className='list-group-item' href="https://www.libertymutualgroup.com/about-lm/our-company/privacy-policy" target="_blank" rel='noreferrer noopener'>Privacy Policy</a>
                            </li>
                            <li>
                                <a className='list-group-item' href="https://www.libertymutualgroup.com/about-lm/our-company/security-policy" target="_blank" rel='noreferrer noopener'>Security Policy</a>
                            </li>
                            <li>
                                <a className='list-group-item' href="https://www.libertymutualgroup.com/about-lm/our-company/terms-and-conditions" target="_blank" rel='noreferrer noopener'>Terms and Conditions</a>
                            </li>
                            <li>
                                <a className='list-group-item' href="https://www.libertymutualgroup.com/about-lm/our-company/member-information_footer" target="_blank" rel='noreferrer noopener'>Member Information</a>
                            </li>
                            <li>
                                <a className='list-group-item' href="https://www.libertymutualgroup.com/about-lm/corporate-information/web-accessibility-policy" target="_blank" rel='noreferrer noopener'>Web Accessibility Policy</a>
                            </li>
                            <li>
                                <a className='list-group-item' href="https://business.libertymutualgroup.com/business-insurance/coverages/workers-compensation" target="_blank" rel='noreferrer noopener'>Workers Compensation</a>
                            </li>
                        </ul>
                        <Disclaimer>
                            <div className="user-control">
                                <span>&copy; {year} Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</span>
                                <br></br>Liberty Mutual is an
                                <a href="https://www.libertymutualgroup.com/about-lm/our-company/equal-employment-opportunity-and-anti-discrimination-policy" aria-label="Equal Opportunity Employer" target="_blank" rel='noreferrer noopener' data-action="action url">
                                    <span className="linkText"> Equal Opportunity Employer </span>
                                </a>
                                and an Equal Housing Insurer.
                            </div>
                        </Disclaimer>
                        <div className="partners">
                            <div className="partners-security">
                                <div>
                                    <a href="https://smp-01.verizonbusiness.com/certinfo/certified.do?CERTID=072505G7001" target="_blank" rel='noreferrer noopener'>
                                        <img src={verizon} alt="Verizon Cybertrust Security: Certified Perimeter" />
                                    </a>
                                </div>
                                <div>
                                    <a href="http://www.bbb.org/boston/business-reviews/insurance-services/liberty-mutual-group-in-boston-ma-89/#bbbonlineclick" target="_blank" rel='noreferrer noopener'>
                                        <img src={bbb} alt="BBB: Accredited Business" />
                                    </a>
                                </div>
                                <div>
                                    <a href="https://sealinfo.verisign.com/splash?form_file=fdf/splash.fdf&type=GOLD&sealid=1&dn=www.libertymutual.com&lang=en" target="_blank" rel='noreferrer noopener'>
                                        <img src={verisign} alt="Verisign Secured" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Footer>
        );
    }
}

export default TuitionFooter;