import React, { Component } from 'react';

class LoadingOverlay extends Component{

    render() {

        var title = this.props.title ? this.props.title : 'Loading';
        var message = this.props.message ? this.props.message : 'One moment please';

        return (
            <div className="loader-overlay">
                <div className="loader-container">
                    <div className="loader">
                        <div className="loader-animation"/>
                        <div className="loader-content">
                            <h5><strong>{title}</strong></h5>
                            <span>{message}&#8230;</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingOverlay;