import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import logger from 'redux-logger';
import persistData from './middleware/persistData'
import {STORE_NAME} from './constants/persistence';

export default function configureStore() {

    let retrievedState;

    try {
        retrievedState = sessionStorage.getItem(STORE_NAME);
        if (retrievedState === null){
        retrievedState = {};
        }
        retrievedState = JSON.parse(retrievedState);
    } catch (err){
        retrievedState = {};
    }

    const middleware = [thunk, logger, persistData];

    return createStore(
        rootReducer,
        retrievedState,
        applyMiddleware(...middleware)
    );
}