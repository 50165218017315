import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

const store = configureStore();

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
